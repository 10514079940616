import React from 'react';

export default function JackpotWinner({gameOver,shortWinnerAddress, lastmanJackpotAmountFormatted, denomFormatted, blocksLeft, aproxTimeRemaining }) {

  return (
    <div className='nes-container is-dark is-centered'>
        {gameOver ? 
            <>
                <h1>{shortWinnerAddress} took home <span className='nes-text is-error'>{lastmanJackpotAmountFormatted} {denomFormatted}</span></h1>
            </> 
        : 
            <>
                {shortWinnerAddress === "No one" ? 
                    <>
                        <h1>
                            Will you be the one that will take <span className='nes-text is-error'>{lastmanJackpotAmountFormatted} {denomFormatted}</span>?
                        </h1>
                    </> 
                    : 
                    <>
                        <h1>
                            {shortWinnerAddress} will take <span className='nes-text is-error'>{lastmanJackpotAmountFormatted} {denomFormatted}</span>
                        </h1>
                    </>
                }
                
                <p>in... {blocksLeft} blocks (around {aproxTimeRemaining})</p>
            </>
        }
    </div>);
}
