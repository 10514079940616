import React, { useState } from 'react';
import { ConnectType, useWallet } from "@terra-money/wallet-provider"
import ConnectWallet from '../components/ConnectWallet';
import Disclaimer from '../components/Disclaimer';
import JackpotWinner from '../components/JackpotWinner';
import { JACKPOT_CONTRACT } from '../constants'
import { Fee } from '@terra-money/terra.js';
import useLCDClient from '../hooks/useLCDClient';
import { MsgExecuteContract, Coin, Coins } from "@terra-money/terra.js"
import { useRefreshingEffect } from '../hooks/useRefreshingEffect';
import { useGasPrice } from "../hooks/useGasPrices"
import { useAddress } from '../hooks/useConnectedAddress';
import BuyTicket from '../components/BuyTicket';
import Rewards from '../components/Rewards';
import PlayersJackpot from '../components/PlayersJackpot';
import Instructions from '../components/Instructions';

function blocksToTime(block) {
        let blocksInSecs = block * 6;
    // convert blockInSecs to months, days, hours, minutes and seconds
    let months = Math.floor(blocksInSecs / (86400 * 30));
    let days = Math.floor((blocksInSecs % (86400 * 30)) / 86400);
    let hours = Math.floor((blocksInSecs % 86400) / 3600);
    let minutes = Math.floor((blocksInSecs % 3600) / 60);
    let seconds = Math.floor(blocksInSecs % 60);
    // if days, hours, or minutes is 0, don't show it
    if (days === 0) {
        days = '';
    } else {
        days = days + 'd ';
    }
    if (hours === 0) {
        hours = '';
    } else {
        hours = hours + 'h ';
    }
    return `${days}${hours}${minutes}m ${seconds}s`;
}

export default function Home() {


    const { connect } = useWallet()
    const address = useAddress()
    const { network, post } = useWallet()
    const lcdClient = useLCDClient()
    
    const [fetchingNewData, setFetchingNewData] = useState();

    /* Last Info */
    /*       
    start_height: u64,
    current_block_height: u64,
    end_height: u64,
    winner_address: String,
    lastman_jackpot_amount: u64,
    players_jackpot_amount: u64,
    jackpot_claimed: bool,
    blocks_limit: u64,
    blocks_incremental: u64,
    total_tickets: u64,
    price: Uint128,
    price_incremental: Uint128,
    next_price: Uint128,
*/
    const [startHeight, setStartHeight] = useState();
    const [currentBlockHeight, setCurrentBlockHeight] = useState();
    const [endHeight, setEndHeight] = useState();
    const [winnerAddress, setWinnerAddress] = useState();
    const [shortWinnerAddress, setShortWinnerAddress] = useState();

    const [lastmanJackpotAmount, setLastmanJackpotAmount] = useState();
    const [playersJackpotAmount, setPlayersJackpotAmount] = useState();
    const [lastmanJackpotAmountFormatted, setLastmanJackpotAmountFormatted] = useState();
    const [playersJackpotAmountFormatted, setPlayersJackpotAmountFormatted] = useState();

    const [jackpotClaimed, setJackpotClaimed] = useState();

    const [blocksLimit, setBlocksLimit] = useState();
    const [blocksLimitFormatted, setBlocksLimitFormatted] = useState();

    const [blocksIncremental, setBlocksIncremental] = useState();
    const [blocksIncrementalFormatted, setBlocksIncrementalFormatted] = useState();

    const [totalTickets, setTotalTickets] = useState();

    const [price, setPrice] = useState();
    const [priceFormatted, setPriceFormatted] = useState();

    const [priceIncremental, setPriceIncremental] = useState();
    const [priceIncrementalFormatted, setPriceIncrementalFormatted] = useState();

    const [nextPrice, setNextPrice] = useState();
    const [nextPriceFormatted, setNextPriceFormatted] = useState();

    const [blocksLeft, setBlocksLeft] = useState();

    const [aproxTimeRemaining, setAproxTimeRemaining] = useState(0);

    const [gameOver, setGameOver] = useState(false);

    const [denom, setDenom] = useState("uusd");
    const [denomFormatted, setDenomFormatted] = useState("UST");




    useRefreshingEffect((isRefreshing) => {
        setFetchingNewData(!isRefreshing);

        async function fetchLastInfo() {
            let response;
            try {
                response = await lcdClient.wasm.contractQuery(JACKPOT_CONTRACT, {
                    "get_last_info": {}
                });
                /*       
                    start_height: u64,
                    current_block_height: u64,
                    end_height: u64,
                    winner_address: String,
                    lastman_jackpot_amount: u64,
                    players_jackpot_amount: u64,
                    jackpot_claimed: bool,
                    blocks_limit: u64,
                    blocks_incremental: u64,
                    total_tickets: u64,
                    price: Uint128,
                    price_incremental: Uint128,
                    next_price: Uint128,
                */
                setStartHeight(response.start_height);
                setCurrentBlockHeight(response.current_block_height);
                setEndHeight(response.end_height);
                let shortAddress = response.winner_address.slice(0, 6) + "..." + response.winner_address.slice(-4)

                if (response.winner_address === "None") {
                    shortAddress = "No one"
                }
                setWinnerAddress(response.winner_address);
                setShortWinnerAddress(shortAddress);
                setLastmanJackpotAmount(response.lastman_jackpot_amount);
                setPlayersJackpotAmount(response.players_jackpot_amount);
                setLastmanJackpotAmountFormatted(response.lastman_jackpot_amount/1000000);
                setPlayersJackpotAmountFormatted(response.players_jackpot_amount/1000000);
                setJackpotClaimed(response.jackpot_claimed);
                setBlocksLimit(response.blocks_limit);
                setBlocksIncremental(response.blocks_incremental);
                setTotalTickets(response.total_tickets);
                setPrice(response.price);
                setPriceFormatted(response.price/1000000);
                setPriceIncremental(response.price_incremental);
                setPriceIncrementalFormatted(response.price_incremental/1000000);
                setNextPrice(response.next_price);
                setNextPriceFormatted(response.next_price/1000000);

                let timeRemaining = response.end_height - response.current_block_height;
                if (timeRemaining < 0) {
                    timeRemaining = 0;
                    setGameOver(true);
                    setAproxTimeRemaining("0m 0s")
                } else {
                    setAproxTimeRemaining(blocksToTime(timeRemaining));
                }
                setBlocksLeft(timeRemaining);
                setBlocksLimitFormatted(blocksToTime(response.blocks_limit));
                setBlocksIncrementalFormatted(blocksToTime(response.blocks_incremental));
        

                setFetchingNewData(false);

            } catch (e) {
                console.log(e)
            }
        }
        fetchLastInfo();

        // gasPrices looks like this:
        // {
        //  "uusd": 150800
        // }
        // get 150800 as "price"
        // get "uusd" as "symbol"
        
    }, 3_000, []);

    

  return (
    <>
        <ConnectWallet address={address}/>
        <Disclaimer />
        <JackpotWinner 
            gameOver={gameOver} 
            shortWinnerAddress={shortWinnerAddress} 
            lastmanJackpotAmountFormatted={lastmanJackpotAmountFormatted} 
            denomFormatted={denomFormatted} 
            blocksLeft={blocksLeft} 
            aproxTimeRemaining={aproxTimeRemaining}
        />
        <PlayersJackpot 
            playersJackpotAmountFormatted={playersJackpotAmountFormatted}
            denomFormatted={denomFormatted}
        />
        {address ? 
            <>
               
                {address ? 
                    <BuyTicket 
                        gameOver={gameOver} 
                        lcdClient={lcdClient} 
                        nextPrice={nextPrice}
                        nextPriceFormatted={nextPriceFormatted}
                        address={address}
                        denom={denom}
                        denomFormatted={denomFormatted}
                    /> 
                    : 
                    <></>
                }
                <Rewards 
                    playersJackpotAmountFormatted={playersJackpotAmountFormatted} 
                    denomFormatted={denomFormatted} 
                    lcdClient={lcdClient} 
                    playersJackpotAmount={playersJackpotAmount}
                    address={address}
                    totalTickets={totalTickets}
                    gameOver={gameOver}
                />
            </>
        : <></>
        }
        <Instructions priceFormatted={nextPriceFormatted} denomFormatted={denomFormatted} blocksIncremental={blocksIncremental} blocksIncrementalFormatted={blocksIncrementalFormatted} priceIncrementalFormatted={priceIncrementalFormatted}/>
        
    </>);
}
