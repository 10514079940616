import React from 'react';

function Instructions({priceFormatted, denomFormatted, startHeight, currentBlockHeight, endHeight, blocksLimit, blocksIncremental, blocksIncrementalFormatted, priceIncrementalFormatted, blocksLeft, aproxTimeRemaining}) {
  return  <div className="nes-container is-dark is-centered">
                <p>Information:</p>
                <ul>
                <li className="text-left">Each ticket costs {priceFormatted} {denomFormatted}.</li>
                <li className="text-left">The jackpot is divided into two, one for the last VC and one for all the VCs participating.</li>
                <li className="text-left">The last person to buy a ticket before the timer runs out wins the jackpot.</li>
                <li className="text-left">Every time someone buys a ticket the countdown timer increments by {blocksIncremental} blocks (~{blocksIncrementalFormatted}) and the price increments by {priceIncrementalFormatted} {denomFormatted}.</li>
                <li className="text-left">At the end of the game, the winner and participants can claim their prize by clicking a button.</li>
                </ul>
            </div>;
}

export default Instructions;
