import React from 'react'

export default function About() {
    return (
        <div className="nes-container is-dark">
            <h2 className="text-center">About The Last Word: Jackpot</h2>
            <p></p>
            <p>Write something, mint a NFT and if you're the last one to do it, get all the jackpot.</p>
            <p></p>
            <p>But the last minutes will be crucial, as others will want to get that piece.</p>
            <p>&nbsp;</p>
            <p>Who will get to have the last word?</p>
            <p>What will that be?</p>
            <p>And, makes you wonder, what will be said along the way?</p>
            </div>
    )
}
