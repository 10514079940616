import React, { useState, useEffect } from 'react';
import loader from '../assets/loader.gif';
import { useRefreshingEffect } from '../hooks/useRefreshingEffect';
import { MsgExecuteContract, Coin, Coins } from "@terra-money/terra.js"
import { ConnectType, useWallet } from "@terra-money/wallet-provider"
import { useGasPrice } from "../hooks/useGasPrices"
import { Fee } from '@terra-money/terra.js';
import { JACKPOT_CONTRACT } from '../constants';


export default function ClaimRewards({address, lcdClient, denom, ticketsBalance, denomFormatted}) {

    const handleSubmit = (e) => {
        e.preventDefault()
    }
    const [fetchingNewData, setFetchingNewData] = useState();
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [disableClaimRewardsBtn, setDisableClaimRewardsBtn] = useState(false);
    const [classClaimRewardsBtn, setClassClaimRewardsBtn] = useState('nes-btn is-primary');
    const [loaderGif, setLoaderGif] = useState(<></>);
    const gasPrices = useGasPrice(denom);
    const [fees, setFees] = useState();
    const [feesFormatted, setFeesFormatted] = useState();
    const { network, post } = useWallet()


    const claimRewards = async () => {
            const msgs = [
                    new MsgExecuteContract(
                        address,
                        JACKPOT_CONTRACT,
                        {
                            "claim_jackpot": { "jackpot_choice": 2 }
                        },
                    )
                ]


            const tmpFee = new Fee(1000000, { uusd: 200000 });
            const txOptions = { 
                msgs,
                fee: tmpFee,
                gasPrices: { uusd: 200000 },
                gasAdjustment: 2 
            }

               setDisableClaimRewardsBtn(true);
            setClassClaimRewardsBtn('nes-btn is-disabled');
            
            await post(txOptions).then(tx => { 
                setSuccessMsg("");
                setErrorMsg("");
                let retries = 0;
                const getTxInfo = async () => {
                    if (retries < 10) {
                        lcdClient.tx.txInfo(tx.result.txhash).then(txInfo => {
                            // if txInfo.raw_log contains "failed to execute" then set errorMsg
                            if (txInfo.raw_log.includes("Jackpot has ended")) {
                                setSuccessMsg("");
                                return setErrorMsg("Transaction failed... Jackpot has ended!");
                            }
                            console.table(txInfo)
                            let quantityClaimed = txInfo.logs[0].events[3].attributes[4].value / 1000000;
                            // format quantityClaimed so it has commas
                            let quantityClaimedFormatted = quantityClaimed.toLocaleString();
                            let urlForTxResult = "https://finder.extraterrestrial.money/" + network.name + "/tx/" + tx.result.txhash;
                            let txHtml = <><p>Success! You've claimed {quantityClaimedFormatted} {denomFormatted}</p>
                                <p><a href={urlForTxResult} target="_blank" rel="noreferrer">See the transaction</a></p>
                                </>
                            setLoaderGif(<></>);
                            setDisableClaimRewardsBtn(false);
                            setClassClaimRewardsBtn('nes-btn is-primary');
                            return setSuccessMsg(txHtml);
                        }).catch(e => {
                            retries += 1;
                            // Would be cool to have some kind of loader.gif in here
                            setLoaderGif(<img src={loader} alt="loader"/>);
                            setSuccessMsg("Sending transaction...");
                            setTimeout(getTxInfo, 4000);
                        });
                    } else {
                        setSuccessMsg("");
                        setErrorMsg("Transaction failed.");
                        setDisableClaimRewardsBtn(false);
                        setClassClaimRewardsBtn('nes-btn is-primary');
                        return;
                    }
                }
                getTxInfo();
            }).catch(e => { 
                if (e.name === "UserDenied") {
                    return setErrorMsg("User denied transaction");                    
                } else if (e.name === "TxFailed") {
                    return setErrorMsg("Transaction failed: " + e.message);
                } else if (e.name === "Timeout") {
                    return setErrorMsg("Transaction timed out");
                } else if (e.name ==="WebExtensionCreateTxFailed") { 
                    return setErrorMsg("Transaction failed: " + e.message);
                } else if (e.name === "CreateTxFailed") {
                    console.table(e);
                    if (e.response) {
                        console.table(e.response)
                    }
                    return setErrorMsg("Transaction failed: " + e.message);
                }
                console.table(e)
                console.log(e.name)
                console.log(e.response.data)
                setErrorMsg(e.response.data.message); 
                setDisableClaimRewardsBtn(false);
                setClassClaimRewardsBtn('nes-btn is-primary');
                return e 
            })
    }

    return (
    <>
        <hr/>
        {ticketsBalance > 0 ? 
        <><form onSubmit={handleSubmit}>
                <div className="nes-field">
                    <h3><p className='nes-text'>Claim Rewards</p></h3>
                    
                    <button className={classClaimRewardsBtn} href="#" disabled={disableClaimRewardsBtn} onClick={claimRewards}>Claim Your Rewards!</button>
                </div>
            </form></> 
        : 
        <p>You have no tickets to claim.</p>
        }
        <p></p>
        {loaderGif}
        {errorMsg ? <p className='nes-text is-error'>{errorMsg}</p> : ""}
        {successMsg ? <div className='nes-text is-error'>{successMsg}</div> : ""}
    </>);
}
