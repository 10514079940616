import React from 'react'

export default function Footer() {
    return (
        <>
            <p></p>
            <div className="nes-container is-dark text-center">
                <span className="nes-text">Made with <i className="nes-icon heart"></i> by <a href="https://spacedollars.money">The Space Dollars Society</a></span>
            </div>
            
        </>
        
    )
}
