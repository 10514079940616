import React from 'react';
// import { useAddress }  from './hooks/useConnectedAddress';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
//import { useAddress }  from './hooks/useConnectedAddress';
import './App.css';
import Footer from './components/Footer';


function App() {
  //const address = useAddress()
  
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
      </Routes>
      <Footer />
    </Router>

  );
}

export default App;
